// FeedbackSection/ErrorMessage.tsx
import React from 'react';
import './FeedbackSection.css';

interface ErrorMessageProps {
  error: string;
  onSwitchToManual: () => void;
  inputMethod?: 'url' | 'manual'; 
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ error, onSwitchToManual, inputMethod }) => {
  const renderErrorContent = () => {
    // Internet Connection errors - add these at the top to catch them first
    if (
      error.includes('Please check your internet connection and try again')
    ) {
      return (
        <>
          Please check your internet connection and try again.
        </>
      );
    }

    // URL Only Method
    // Empty URL
    if (error.includes("Please enter a valid URL")) {
      return (
        <>
          Please enter a URL to continue.
        </>
      );
    }
    // Invalid URL format
    if (error.includes("This does not look like a valid URL")) {
      return (
        <>
          This doesn't look like a valid URL. Please check the URL format, or{' '}
          <button onClick={onSwitchToManual} className="inline-button">
            try URL + Description📝
          </button>
          ?
        </>
      );
    }
    // Invalid URL cases
    if (error.includes("This URL does not appear to be a job posting")) {
      return (
        <>
          Psst... This URL does not appear to be a job posting. Can you double check the URL, or{' '}
          <button onClick={onSwitchToManual} className="inline-button">
            try URL + Description📝
          </button>
          ?
        </>
      );
    }

    // Site access restrictions
    if (error.includes("We are not able to process job posts from certain sites")) {
      return (
        <>
          Sorry... We couldn't extract job details from certain sites. Could you find the original post, or{' '}
          <button onClick={onSwitchToManual} className="inline-button">
            try URL + Description📝
          </button>
          ?
        </>
      );
    }

    // URL/Content Fetching errors (from content_service.py)
    if (error.includes('Unable to fetch the job posting. Please try again later.')) {
      return (
        <>
          Sorry... We are unable to fetch the job posting. Please{' '}
          <button onClick={onSwitchToManual} className="inline-button">
            try URL + Description📝
          </button>
          {' '}or try again later.
        </>
      );
    }

    // Job availability errors (from content_service.py)
    if (
      error.includes('This job posting could not be found.') || 
      error.includes('This position is no longer accepting applications.')
    ) {
      return (
        <>
          This job posting appears to be unavailable. You can{' '}
          <button onClick={onSwitchToManual} className="inline-button">
            try URL + Description📝
          </button>
          {' '}or search for a different position.
        </>
      );
    }

    // Backend content validation errors
    if (error.includes('Job description content is required.') || 
        error.includes('The content does not appear to be a job description.') || 
        error.includes('The content appears to be missing essential job information.') ||
        error.includes('The job description seems too short.') ||
        error.includes('This job posting is quite lengthy.')
      ) {
      return (
        <>
          Sorry... We are unable to fetch the job posting properly. Please{' '}
          <button onClick={onSwitchToManual} className="inline-button">
            try URL + Description📝
          </button>
          {' '}instead.
        </>
      );
    }

    // Groq API Service errors
    if (error.includes('Our AI service is temporarily unavailable. Please try again in a few minutes.')) {
      return 'Our service is temporarily unavailable. Please try again later.';
    }
    if (error.includes('We\'re experiencing high demand. Please try again in a few minutes.')) {
      return 'We\'re experiencing high demand. Please try again in a few minutes.';
    }
    if (error.includes('There was an authentication error. Please try again.')) {
      return 'Our service is temporarily unavailable. Please try again later.';
    }

    // Legal restrictions
    if (error.includes("Unavailable For Legal Reasons")) {
      return (
        <>
          Sorry... We couldn't extract the job details from this URL. Please{' '}
          <button onClick={onSwitchToManual} className="inline-button">
            try URL + Description📝
          </button>
          {' '}instead.
        </>
      );
    }
    
    // URL+Description Method
    // Frontend validation errors (from validation.ts)
    if (inputMethod === 'manual') {
      if (error.includes('seems too short')) {
        return 'Psst... The job description seems too short. Please provide more details.';
      }
      if (error.includes('is too long')) {
        return 'Psst... Job description is too long. Please provide a concise version.';
      }
      if (error.includes('does not appear to be a job description')) {
        return 'Psst... Please ensure the job description includes sections like requirements, responsibilities, or qualifications.';
      }
      if (error.includes('suspicious patterns')) {
        return 'The content contains invalid formatting. Please provide plain text job description.';
      }
      if (error.includes('provide both URL and job description')) {
        return 'Please provide both the job URL and description.';
      }
      if (error.includes('Please provide the job description content')) {
        return 'Please provide the job description to continue.';
      }
    }

    // Invalid submission method
    if (error.includes('Invalid submission method')) {
      return 'An unexpected error occurred. Please refresh the page and try again.';
    }

    // Network or timeout errors
    if (error.includes('timeout') || error.includes('network error')) {
      return 'Connection issues detected. Please check your internet connection and try again.';
    }

    // Default fallback with URL + Description suggestion
    return (
      <>
        Sorry... An unexpected error occurred. Please{' '}
        <button onClick={onSwitchToManual} className="inline-button">
          try URL + Description📝
          </button>
        {' '}or try again later.
      </>
    );
  };

  return (
    <div className="error-message" data-testid='error-msg'>
      {renderErrorContent()}
    </div>
  );
};