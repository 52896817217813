export interface ValidationResponse {
  isValid: boolean;
  errorMsg: string; 
}

export interface SubmissionData {
  url: string;
  content?: string | null;
  method: 'url' | 'manual';
}

// Email validation constants
const EMAIL_VALIDATION = {
    MAX_LENGTH: 254, // Maximum length per RFC 5321
    MIN_LENGTH: 3,
    PATTERN: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  };

// Constants for content validation
const CONTENT_VALIDATION = {
  MIN_LENGTH: 1000,
  MAX_LENGTH: 15000,
  REQUIRED_SECTIONS: [
      'experience',
      'qualifications', 
      'qualification',
      'responsibilities',
      'responsibility',
      'requirements',
      'requirement',
      'skills',
      'skill',
      'what you\'ll do',
      'what you will do',
      'what we\'re looking for',
      'what we are looking for',
      'your background',
      'about you',
      'the role'
  ],
  SUSPICIOUS_PATTERNS: [
      /\{.*\}|\[.*\]/, // Likely code or JSON
      /<\/?[^>]+(>|$)/, // HTML tags
  ]
};

export const validateEmail = (email: string): ValidationResponse => {
    if (!email || typeof email !== 'string') {
      return {
        isValid: false,
        errorMsg: 'Please enter an email address'
      };
    }
  
    const cleanEmail = email.trim().toLowerCase();
  
    // Check length
    if (cleanEmail.length > EMAIL_VALIDATION.MAX_LENGTH) {
      return {
        isValid: false,
        errorMsg: 'Email address is too long'
      };
    }
  
    if (cleanEmail.length < EMAIL_VALIDATION.MIN_LENGTH) {
      return {
        isValid: false,
        errorMsg: 'Email address is too short'
      };
    }
  
    // Check format using regex
    if (!EMAIL_VALIDATION.PATTERN.test(cleanEmail)) {
      return {
        isValid: false,
        errorMsg: 'Please enter a valid email address'
      };
    }
  
    return {
      isValid: true,
      errorMsg: ''
    };
  };

// Modified URL validation to accept a flag for domain restriction check
const validateUrlOnly = (url: string, checkValidJobUrl: boolean = true, checkRestrictedDomains: boolean = true): ValidationResponse => {
  let val = true;
  let msg = ''; 

  if (!url || typeof url !== "string" || url.trim() === "") {
      msg = "Please enter a valid URL.";
      val = false;
  } else if (!validateUrl(url)) {
      msg = "This does not look like a valid URL?";
      val = false;
  } else if (checkValidJobUrl && !isValidJobUrl(url)) {
      msg = "This URL does not appear to be a job posting.";
      val = false;
  } else if (checkRestrictedDomains && isRestrictedDomain(url)) {
      // Only check restricted domains if flag is true
      msg = "We are not able to process job posts from certain sites.";
      val = false;
  }

  return {isValid: val, errorMsg: msg};
};

// Updated combined validation to skip domain restriction check
const validateUrlAndContent = (url: string, content: string): ValidationResponse => {
  // Validate URL but skip valid job url and restricted domain check
  const urlValidation = validateUrlOnly(url, false, false);
  if (!urlValidation.isValid) {
      return urlValidation;
  }

  // Then validate content
  return validateContent(content);
};

// Content validation helper
const validateContent = (content: string): ValidationResponse => {
  if (!content || typeof content !== 'string') {
      return {
          isValid: false,
          errorMsg: 'Please provide the job description content'
      };
  }

  const cleanContent = content.trim().toLowerCase();

  // Check minimum length
  if (cleanContent.length < CONTENT_VALIDATION.MIN_LENGTH) {
      return {
          isValid: false,
          errorMsg: `Job description seems too short. Please provide more details (minimum ${CONTENT_VALIDATION.MIN_LENGTH} characters)`
      };
  }

  // Check maximum length
  if (cleanContent.length > CONTENT_VALIDATION.MAX_LENGTH) {
      return {
          isValid: false,
          errorMsg: 'Job description is too long. Please provide a concise version'
      };
  }

  // Check for required sections
  const hasSections = CONTENT_VALIDATION.REQUIRED_SECTIONS.some(
      section => cleanContent.includes(section)
  );
  if (!hasSections) {
      return {
          isValid: false,
          errorMsg: 'Content does not appear to be a job description. Please ensure it includes sections like requirements, responsibilities, or qualifications'
      };
  }

  // Check for suspicious patterns
  for (const pattern of CONTENT_VALIDATION.SUSPICIOUS_PATTERNS) {
      if (pattern.test(cleanContent)) {
          return {
              isValid: false,
              errorMsg: 'Content contains suspicious patterns or formatting'
          };
      }
  }

  return { isValid: true, errorMsg: '' };
};

// Original helper functions
const isValidJobUrl = (url: string): boolean => {
  if (typeof url !== "string") return false;
  const keywords = [
      "job",
      "role",
      "detail",
      "candidate",
      "apply",
      "position",
      "career",
      "id",
      "search",
  ];
  const lowercaseUrl = url.toLowerCase();
  return keywords.some((keyword) => lowercaseUrl.includes(keyword));
};

const isRestrictedDomain = (url: string): boolean => {
  if (typeof url !== "string") return false;
  const restrictedDomains = [
      ".linkedin.com",
      ".glassdoor.com",
      ".joinhandshake.com",
      ".wellfound.com",
      ".indeed.com",
      ".lhh.com",
  ];
  return restrictedDomains.some((domain) => url.includes(domain));
};

const validateUrl = (url: string): boolean => {
  if (typeof url !== 'string') return false;
  try {
      new URL(url);
      return true;
  } catch (e) {
      return false;
  }
};

// Main validation function with proper type annotations
export const ValidateInput = (submissionData: SubmissionData): ValidationResponse => {
  const { url, content, method } = submissionData;

  if (method === 'url') {
      return validateUrlOnly(url, true);
  } else if (method === 'manual') {
      if (!content) {
          return {
              isValid: false,
              errorMsg: 'Please provide both URL and job description'
          };
      }
      return validateUrlAndContent(url, content);
  }

  return {
      isValid: false,
      errorMsg: 'Invalid submission method'
  };
};