import React from 'react';
import './LoadingOverlay.css';

const Firework = ({ position }) => (
  <div 
    className="firework" 
    style={{
      '--x': `${position.x}vw`,
      '--y': `${position.y}vh`
    } as React.CSSProperties}
  />
);

export const LoadingOverlay = ({ visible }) => {
  if (!visible) return null;
  
  return (
    <div className="loading-overlay">
      <div className="fireworks-container">
        {[...Array(20)].map((_, i) => {
          const position = {
            x: Math.random() * 100,
            y: Math.random() * 75 
          };
          return <Firework key={i} position={position} />;
        })}
      </div>
      
      <div className="loading-content">
        <div className="duck-container">
          <div className="party-hat">
            <div className="hat-body"></div>
            <div className="hat-trim"></div>
            <div className="hat-pom"></div>
          </div>
          <img src="/sidebar-screenshot.png" alt="Career Duck" className="duck-logo" />
        </div>
        
        <p className="celebration-message">
          ⏳ Generating job summary and bullet point ideas for you...
        </p>
        <p className="helper-message">
          Now sign in through Google is available to keep your history!
        </p>
      </div>
    </div>
  );
};

export default LoadingOverlay;