import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { Analytics } from "@vercel/analytics/react";
import { ToastContainer, toast } from 'react-toastify';
import { useTheme } from './hooks/useTheme';
import { useAuth, SIGN_OUT_EVENT } from './hooks/useAuth';
import { LoadingOverlay } from './components/LoadingOverlay';
import { Header } from './components/Header';
import { Headline } from './components/Headline';
import { TabInput } from './components/TabInput';
import { FeedbackMessage, ErrorMessage } from './components/FeedbackSection';
import { JobResults } from './components/JobResults';
import { Footer } from './components/Footer';
import { Sidebar } from './components/Sidebar';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsOfService from './components/TermsOfService';
import { JobData, SubmissionData, ApiError } from './types/types';
import { trackGAEvent, googleAnalytics } from './utils/analytics';
import { ValidateInput, validateEmail } from './utils/validation';
import { submitJobData } from './services/jobService';
import { sendJobEmail } from './services/emailService';
import './styles/theme.css';
import './styles/layout.css';
import 'react-toastify/dist/ReactToastify.css';  // Add this import

const API_URL = process.env.REACT_APP_API_URL || 'https://decisive-fold-436618-h2.uk.r.appspot.com';

function App() {
  const { theme, toggleTheme } = useTheme();
  const { user, loading } = useAuth();
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  const [url, setUrl] = useState('');
  const [inputMethod, setInputMethod] = useState<'url' | 'manual'>('url');
  const [jobData, setJobData] = useState<JobData | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showFeedback, setShowFeedback] = useState(false);
  const [email, setEmail] = useState('');
  const [submittedUrl, setSubmittedUrl] = useState('');

  useEffect(() => {
    toast.success('🚨 Sign in on laptop to unlock Personal History!', {
      position: "top-right",
      autoClose: 7000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  }, []); // Empty dependency array means this runs once when component mounts

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => {
      setIsOnline(false);
      setIsLoading(false);  // Stop loading state if we go offline
      setError('Please check your internet connection and try again.');
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    const handleSignOut = () => {
      // Clear all relevant states
      setUrl('');
      setInputMethod('url');
      setJobData(null);
      setError('');
      setShowFeedback(false);
      setEmail('');
      setSubmittedUrl('');
    };


    // Add event listener
    window.addEventListener(SIGN_OUT_EVENT, handleSignOut);

    // Cleanup
    return () => {
      window.removeEventListener(SIGN_OUT_EVENT, handleSignOut);
    };
  }, []);

  useEffect(() => {
    document.documentElement.setAttribute('data-theme', theme);
  }, [theme]);

  const handleSubmit = async (submissionData: SubmissionData) => {
    if (!isOnline) {
      setError('Please check your internet connection and try again.');
      return;
    }
  
    trackGAEvent('gain_insights_clicked', 'engagement', submissionData.url);
  
    setIsLoading(true);
    setError('');
    setJobData(null);
    setShowFeedback(false);
    setSubmittedUrl(submissionData.url);

    const validationResult = ValidateInput(submissionData);
    if (!validationResult.isValid) {
      setError(validationResult.errorMsg);
      toast.error('Failed to retrieve outputs 😕');
      trackGAEvent('input_validation_error', 'error', validationResult.errorMsg);
      setIsLoading(false);
      return;
    }
  
    try {
      await submitJobData(
        submissionData,
        API_URL,
        (data) => {
          setJobData(data);
          setShowFeedback(true);
          toast.success('Outputs retrieved successfully! 🎉');
          trackGAEvent('job_data_retrieved', 'success', submissionData.url);
        },
        (errorMsg) => {
          setError(errorMsg);
          toast.error('Failed to retrieve outputs 😕');
          trackGAEvent('job_data_error', 'error', errorMsg);
        }
      );
    } catch (error) {
      // Error already handled in service
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendEmail = async () => {
    const emailValidation = validateEmail(email);
    if (!emailValidation.isValid) {
      toast.error(emailValidation.errorMsg);
      return;
    }

    if (!jobData || !jobData.parsed_bullets) {
      toast.error('Please ensure job data is loaded first.');
      return;
    }

    try {
      await sendJobEmail(email, jobData, submittedUrl, API_URL);
      trackGAEvent('email_sent', 'engagement', email);
      toast.success('💡 Bullet points have been sent!');
    } catch (error) {
      console.error('Email sending error:', error);
      toast.error('Failed to send email. Please try again.');
    }
  };

  const handleSwitchToManual = () => {
    trackGAEvent('switch_to_manual_from_error', 'navigation', 'from_error');
    const tabInputElement = document.querySelector('.tab-button:nth-child(2)') as HTMLElement;
    if (tabInputElement) {
      tabInputElement.click();
    }
  };

  const handleSearchRecordClick = (record: any) => {
    try {
      // Check if the data exists in the response object
      if (record.response.parsed_summary && record.response.parsed_bullets) {
        setJobData({
          parsed_summary: record.response.parsed_summary,
          parsed_bullets: record.response.parsed_bullets
        });
        setShowFeedback(true);

        // Set the URL from the record
        if (record.url) {
          setUrl(record.url);
        }
        
        // Track the event
        trackGAEvent('history_record_clicked', 'engagement', `${record.job_title} @ ${record.company}`);
        
        // Show success toast
        toast.success('Successfully loaded your history! 🎉');
      } else {
        console.error('Missing parsed data in record:', record);
        toast.error('Unable to load historical data');
      }
    } catch (error) {
      console.error('Error processing search record:', error);
      toast.error('Unable to load historical data');
    }
  };
    
  return (
    <Router>
      <Routes>
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsOfService />} />
        <Route path="/" element={
          <div className="app-container">
            {user && (
              <Sidebar 
                onExpandChange={setIsSidebarExpanded}
                onSearchRecordClick={handleSearchRecordClick}
              />
            )}
            <div className={`main-content ${isSidebarExpanded ? 'sidebar-expanded' : ''}`}>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme={theme === 'dark' ? 'dark' : 'light'}
              />
              {isLoading && <LoadingOverlay visible={isLoading} />}
              <Header/>
              <main>
                <Headline />
                <TabInput 
                  onSubmit={handleSubmit}
                  isLoading={isLoading}
                  url={url}
                  setUrl={setUrl}
                  inputMethod={inputMethod}
                  setInputMethod={setInputMethod}
                />

                {showFeedback && (
                  <FeedbackMessage
                    onSwitchToManual={handleSwitchToManual}
                    email={email}
                    setEmail={setEmail}
                    onSendEmail={handleSendEmail}
                  />
                )}

                {error && (
                  <ErrorMessage 
                    error={error}
                    onSwitchToManual={handleSwitchToManual}
                    inputMethod={inputMethod}
                  />
                )}

                <JobResults jobData={jobData} />
              </main>
            </div>
            <Footer /> {/* Add the Footer component here */}
          </div>
        } />
      </Routes>
      <Analytics />
    </Router>
  );
}

export default App;