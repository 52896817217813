import React from 'react';
import { useAuth } from '../../hooks/useAuth';
import './Headline.css';

export const Headline: React.FC = () => {
  const { user } = useAuth();
  
  return (
    <div className="headline-wrapper">
        {user 
          ? <>
              <h1 className="headline">Welcome, <span className="subtitle">{user.displayName}</span></h1>
            </>
          : (
            <>
              <h1 className="headline">Get Job Summary and Resume Bullet Points</h1>
              <h1 className="subtitle">The Easy Way</h1>
            </>
          )
        }
    </div>
  );
};