import React from 'react';
import './Header.css';
import { Information } from '../Information/Information';
import { SignIn } from '../SignIn/SignIn';

export const Header: React.FC = () => {
    return (
      <div className='header'>
        <div className='header-container'>
          <div className="logo-container">
            <img src="/duck-logo.png" alt="Career Duck Logo" className="App-logo" />
            <h1>Career Duck</h1>
          </div>
          <div className="signin-info-container">
            <SignIn />
            <Information />
          </div>
        </div>
      </div>
    );
  };