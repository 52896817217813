// services/emailService.ts
import axios from 'axios';
import { JobData } from '../types/types';

const formatBulletPoints = (parsedBullets: { [s: string]: unknown; } | ArrayLike<unknown>) => {
  return Object.entries(parsedBullets)
    .map(([responsibility, bullets]) => 
      `${responsibility}\n${(bullets as string[]).map((bullet: string) => `- ${bullet}`).join('\n')}`)
    .join('\n\n');
};

const createEmailContent = (jobData: JobData, submittedUrl: string) => {
  const bulletPointsText = formatBulletPoints(jobData.parsed_bullets);
  const keywordsText = jobData.parsed_summary.keywords.join(', ');

  return `
    <p>Someone who cares about you a lot has shared the job below from Career Duck (<a href="https://www.careerduck.io">careerduck.io</a>)&nbsp;❤️</p>
    
    <p>This is the original job posting URL:<br>
    <a href="${submittedUrl}">${submittedUrl}</a></p>
    
    <p>----------------------------------------------------------------------------------------------------</p>

    <p><strong>Title:</strong> ${jobData.parsed_summary.title} at ${jobData.parsed_summary.company_name}</p>
    
    <p><strong>Location:</strong> ${jobData.parsed_summary.location}</p>

    <p><strong>Keywords:</strong> ${keywordsText}</p>
    
    <p>💡&nbsp;<strong>Bullet Points Ideas</strong></p>
    
    <pre>${bulletPointsText}</pre>

    <p>----------------------------------------------------------------------------------------------------</p>

    <p>Get questions? Quack at us at 📧 <a href="mailto:contact@careerduck.io">contact@careerduck.io</a></p>
  `;
};

export const sendJobEmail = async (email: string, jobData: JobData, submittedUrl: string, apiUrl: string) => {
  const content = createEmailContent(jobData, submittedUrl);
  
  return axios.post(`${apiUrl}/send-email`, {
    email,
    subject: `🦆 Bullet Point Ideas for ${jobData.parsed_summary.title} at ${jobData.parsed_summary.company_name} from Career Duck`,
    content,
    contentType: 'text/html'
  });
};