import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { getUserProfile } from '../../services/userProfileService';
import { UserProfile } from '../../services/userProfileService';
import { Settings as SettingsComponent } from '../Settings/Settings';
import { Settings, LogOut } from 'lucide-react';
import { trackGAEvent } from '../../utils/analytics';
import './SignIn.css';

export function SignIn() {
  const { user, signIn, signOut } = useAuth();
  const [showSettings, setShowSettings] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [profileData, setProfileData] = useState<UserProfile | null>(null);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Clear profile data if no user
    if (!user) {
      setProfileData(null);
      return;
    }

    const fetchProfile = async () => {
      try {
        const profile = await getUserProfile(user.uid);
        setProfileData(profile);
      } catch (error) {
        console.error('Error fetching profile:', error);
        setProfileData(null);
      }
    };

    fetchProfile();

    // Cleanup function to clear profile data when component unmounts
    // or when user changes/signs out
    return () => {
      setProfileData(null);
    };
  }, [user]); // dependency on user ensures this runs on user change

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !(dropdownRef.current as HTMLElement).contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSignIn = async () => {
    trackGAEvent('sign_in_button_click', 'engagement', 'Sign In Button Click');
    await signIn();
  };

  if (!user) {
    return (
      <div className="signin-container">
        <button className="signin-button" onClick={handleSignIn}>
          Sign In
        </button>
      </div>
    );
  }

  return (
    <div className="signin-container">
      <div className="profile-menu" ref={dropdownRef}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="profile-button"
        >
          {profileData && profileData.photoURL ? (
            <img
              src={profileData.photoURL}
              alt="Profile"
              className="profile-image"
              referrerPolicy="no-referrer"
              onError={(e) => {
                console.error('Image failed to load:', e);
                // Fallback to initial if image fails to load
                (e.target as HTMLImageElement).style.display = 'none';
                let initial = '?';
                if (profileData && profileData.displayName && profileData.displayName[0]) {
                  initial = profileData.displayName[0];
                } else if (user && user.email && user.email[0]) {
                  initial = user.email[0];
                }
                initial = initial.toUpperCase();
                
                const parent = (e.target as HTMLImageElement).parentElement;
                if (parent) {
                  parent.appendChild(
                    Object.assign(document.createElement('div'), {
                      className: 'profile-initial',
                      textContent: initial
                    })
                  );
                }
              }}
            />
          ) : (
            <div className="profile-initial">
              {(() => {
                let initial = '?';
                if (profileData && profileData.displayName && profileData.displayName[0]) {
                  initial = profileData.displayName[0];
                } else if (user && user.email && user.email[0]) {
                  initial = user.email[0];
                }
                return initial.toUpperCase();
              })()}
            </div>
          )}
        </button>

        {isOpen && (
          <div className="dropdown-menu">
            <button
              onClick={() => {
                setIsOpen(false);
                setShowSettings(true);
              }}
              className="dropdown-item"
            >
              <Settings className="dropdown-icon" />
              Settings
            </button>
            <button
              onClick={() => {
                setIsOpen(false);
                signOut();
              }}
              className="dropdown-item"
            >
              <LogOut className="dropdown-icon" />
              Sign Out
            </button>
          </div>
        )}
      </div>
      {showSettings && (
      <SettingsComponent 
        isOpen={showSettings} 
        onClose={() => setShowSettings(false)} 
      />
    )}
  </div>
  );
}