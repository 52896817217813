import { useState, useEffect } from 'react';
import { User, getAuth, browserLocalPersistence, setPersistence, GoogleAuthProvider } from 'firebase/auth';
import { signInWithGoogle, signOutUser, subscribeToAuthChanges } from '../utils/firebase';
import { createOrUpdateUserProfile } from '../services/userProfileService';
import { toast } from 'react-toastify';

export const SIGN_OUT_EVENT = 'userSignOut';

export const useAuth = () => {
  const [user, setUser] = useState<User | null>(null);
  const [userProfile, setUserProfile] = useState<any>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const auth = getAuth();
    setPersistence(auth, browserLocalPersistence);

    const unsubscribe = subscribeToAuthChanges(async (user) => {
      setUser(user);
      if (user) {
        const profile = await createOrUpdateUserProfile(user);
        setUserProfile(profile);
      } else {
        setUserProfile(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const signIn = async () => {
    try {      
      const auth = getAuth();
      await auth.signOut();

      // Add delay to prevent rate limiting
      await new Promise(resolve => setTimeout(resolve, 1000));

      const result = await signInWithGoogle();
      if (!result.success) {
        console.error('Sign in error:', result.error);
        return false;
      }
      toast.success('Successfully signed in!');    
      return true;
    } catch (error) {
      console.error('Sign in error:', error);
      toast.error('Sign in failed. Please try again.');
      return false;
    }
  };

  const completeSignOut = async () => {
    try {
      // 1. Clear local state
      setUserProfile(null);
      setUser(null);
      
      // 3. Clear cookies
      document.cookie.split(";").forEach(function(c) { 
        document.cookie = c.replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); 
      });

      // 4. Sign out from Firebase
      const result = await signOutUser();
      if (!result.success) {
        throw new Error('Firebase sign out failed');
      }

      // 5. Dispatch event for other components
      window.dispatchEvent(new Event(SIGN_OUT_EVENT));
      
      // 6. Show success message
      toast.success('Successfully signed out!');

      return true;
    } catch (error) {
      console.error('Sign out error:', error);
      toast.error('Error during sign out. Please try again.');
      return false;
    }
  };

  return { 
    user, 
    userProfile, 
    loading, 
    signIn, 
    signOut: completeSignOut 
  };
};