import React, { useState, useEffect, useRef } from 'react';
import { X } from 'lucide-react';
import { trackGAEvent } from '../../utils/analytics';
import './Information.css';

export const Information = () => {
    const [isOpen, setIsOpen] = useState(false);
    const modalRef = useRef(null);

    useEffect(() => {
      try {
        // Try to access localStorage - this might fail in incognito
        const hasVisitedBefore = localStorage.getItem('hasVisitedBefore');
        
        if (!hasVisitedBefore) {
          setIsOpen(true);
          
          trackGAEvent(
            'about_modal_auto_open',
            'engagement',
            'First Time Visitor Auto Modal Open'
          );

          // Set flag in localStorage to identify returning visitors
          localStorage.setItem('hasVisitedBefore', 'true');
        }
      } catch (error) {
        // localStorage not available - possibly in incognito
        console.log('Storage not available, modal behavior may be affected');
      }
    }, []);

    // Add escape key handler
    useEffect(() => {
      const handleEscape = (e: KeyboardEvent) => {
        if (e.key === 'Escape') setIsOpen(false);
      };

      if (isOpen) {
        document.addEventListener('keydown', handleEscape);
        return () => document.removeEventListener('keydown', handleEscape);
      }
    }, [isOpen]);
      
    const handleAboutClick = () => {
      trackGAEvent('about_button_click', 'engagement', 'About Modal Open');
      setIsOpen(true);
    };

    const handleClickOutside = (e: React.MouseEvent) => {
      if (modalRef.current && !(modalRef.current as HTMLElement).contains(e.target as Node)) {
        setIsOpen(false);
      }
    };

    const handleLinkedInClick = (clickSource: 'image' | 'icon') => {
        trackGAEvent(
            'linkedin_profile_click', 
            'social', 
            `LinkedIn Click - ${clickSource}`, 
            { link_url: 'https://www.linkedin.com/in/jenchinwang/' }
        );
    };

    const handleClose = (dontShowAgain: boolean) => {
        if (dontShowAgain) {
            try {
            localStorage.setItem('hasVisitedBefore', 'true');
            } catch (error) {
            console.log('Unable to save preference');
            }
        }
        setIsOpen(false);
    };

    return (
      <div>
          <button 
          onClick={handleAboutClick}
          className="about-button"
          aria-label="About"
          >
            About
        </button>

      {isOpen && (
          <div className="modal-overlay" onClick={handleClickOutside}>
            <div className="modal-content" ref={modalRef}>
            <div className="info-header">
              <h2 className="info-title">About Career Duck</h2>
              <button 
                onClick={() => setIsOpen(false)}
                className="close-button"
              >
                <X width={20} height={20} />
              </button>
            </div>

            <div className="info-content">
            <h3 className="info-section-title">Our Vision</h3>
              <section className="info-section">
                <p className="info-text">
                Landing the dream job shouldn't feel impossible. Career Duck combines AI with your experiences to make crafting standout resumes fast and stress-free.
                </p>
                <p className="info-text">
                We analyze job postings to uncover exactly what employers want, then help transform your experiences into compelling resume points. You bring your story; Career Duck helps you make it shine ✨
                </p>
              </section>

              <h3 className="info-section-title">How It Works</h3>
              <section className="info-section">
                <div>
                <div className="info-step-container">
                  <p className="info-text">1️⃣ Found an exciting job? Just copy the link</p>
                </div>
                <div className="info-step-container">
                  <p className="info-text">2️⃣ Drop it in and watch our AI work its magic</p>
                </div>
                <div className="info-step-container">
                  <p className="info-text">3️⃣ Get matched skills and resume point ideas</p>
                </div>
                <div className="info-step-container">
                  <p className="info-text">4️⃣ Make each point your own and stand out</p>
                  </div>
                </div>
              </section>    

              <h3 className="info-section-title">Team</h3>
              <section className="info-section">
                <div className="team-member">
                  <img src="/career-duck-founder.jpg" alt="James Wang" className="avatar" />
                  <div>
                    <h4 className="team-title">James Wang</h4>
                    <p className="info-text">
                      Founder
                    </p>
                    <a 
                      href="https://www.linkedin.com/in/jenchinwang" 
                      target="_blank" 
                      rel="noopener noreferrer" 
                      className="linkedin-link"
                      onClick={() => handleLinkedInClick('icon')}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor" 
                          viewBox="0 0 16 16"
                          className="linkedin-icon">
                          <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
                      </svg>
                    </a>
                  </div>
                </div>
              </section>
            </div>

            <div className="info-footer">
                <div className="info-footer-content">

                    <span className="info-footer-text">Contact us at</span>
                    <a 
                        href="mailto:james.wang@careerduck.io" 
                        className="info-contact-link"
                        >
                        contact@careerduck.io
                    </a>
                </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};