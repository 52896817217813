import { useState, useEffect } from 'react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../utils/firebase';
import { useAuth } from './useAuth';

export type Theme = 'light' | 'dark';

export const useTheme = () => {
  const [theme, setTheme] = useState<Theme>(() => {
    // Initialize from DOM on mount
    return (document.documentElement.getAttribute('data-theme') as Theme) || 'light';
  });
  const { user, loading } = useAuth();

  const getSystemTheme = (): Theme => {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    return prefersDark ? 'dark' : 'light';
  };

  const applyTheme = (newTheme: Theme) => {
    // Apply to DOM first
    document.documentElement.setAttribute('data-theme', newTheme);
    // Then update state
    setTheme(newTheme);
  };

  useEffect(() => {
    const loadTheme = async () => {
      try {
        if (loading) return;

        if (!user) {
          const systemTheme = getSystemTheme();
          applyTheme(systemTheme);
          return;
        }

        const userRef = doc(db, 'User_Profile', user.uid);
        const userSnap = await getDoc(userRef);
        
        if (userSnap.exists() && userSnap.data().theme) {
          const savedTheme = userSnap.data().theme as Theme;
          applyTheme(savedTheme);
        } else {
          const systemTheme = getSystemTheme();
          applyTheme(systemTheme);
          
          if (userSnap.exists()) {
            await updateDoc(userRef, { theme: systemTheme });
          }
        }
      } catch (error) {
        console.error('Error loading theme:', error);
        const systemTheme = getSystemTheme();
        applyTheme(systemTheme);
      }
    };

    loadTheme();
  }, [user, loading]);

  const toggleTheme = async () => {
    try {
      // Get the current DOM theme to ensure we're in sync
      const currentTheme = document.documentElement.getAttribute('data-theme') as Theme;
      const newTheme: Theme = currentTheme === 'light' ? 'dark' : 'light';
      
      // Apply to DOM first
      document.documentElement.setAttribute('data-theme', newTheme);
      // Then update state
      setTheme(newTheme);

      if (user.uid) {
        const userRef = doc(db, 'User_Profile', user.uid);
        await updateDoc(userRef, { theme: newTheme });
      }
      
      return newTheme;
    } catch (error) {
      console.error('Error toggling theme:', error);
      return theme;
    }
  };

  return { theme, toggleTheme };
};