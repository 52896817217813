import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import { User } from 'firebase/auth';
import { db } from '../utils/firebase';

export interface UserProfile {
  uid: string;
  email: string | null;
  displayName: string | null;
  photoURL: string | null;
  createdAt: any;
  lastLoginAt: any;
  providerId: string;
  emailVerified: boolean;
  theme: 'light' | 'dark'; // Changed from optional to required with a default
}

export const createOrUpdateUserProfile = async (user: User): Promise<UserProfile> => {
  const userRef = doc(db, 'User_Profile', user.uid);
  const userSnap = await getDoc(userRef);

  // Default to system preference for theme
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const defaultTheme = prefersDark ? 'dark' : 'light';

  const userProfile: UserProfile = {
    uid: user.uid,
    email: user.email,
    displayName: user.displayName,
    photoURL: user.photoURL,
    lastLoginAt: new Date(),
    providerId: user.providerData[0].providerId || 'unknown',
    emailVerified: user.emailVerified,
    createdAt: userSnap.exists() ? userSnap.data().createdAt : new Date(),
    theme: userSnap.exists() && userSnap.data().theme ? userSnap.data().theme : defaultTheme, // Always provide a valid theme
  };

  if (!userSnap.exists()) {
    // New user - create profile
    await setDoc(userRef, userProfile);
  } else {
    // Existing user - update profile
    await updateDoc(userRef, {
      email: userProfile.email,
      displayName: userProfile.displayName,
      photoURL: userProfile.photoURL,
      lastLoginAt: userProfile.lastLoginAt,
      providerId: userProfile.providerId,
      emailVerified: userProfile.emailVerified,
      // Only update theme if it exists in the profile
      ...(userProfile.theme && { theme: userProfile.theme })
    });
  }

  return userProfile;
};

export const getUserProfile = async (uid: string): Promise<UserProfile | null> => {
  const userRef = doc(db, 'User_Profile', uid);
  const userSnap = await getDoc(userRef);

  if (userSnap.exists()) {
    return userSnap.data() as UserProfile;
  }
  return null;
};