// FeedbackSection/FeedbackMessage.tsx
import React from 'react';
import './FeedbackSection.css';

interface FeedbackMessageProps {
  onSwitchToManual?: () => void;
  email: string;
  setEmail: (email: string) => void;
  onSendEmail: () => void;
}

export const FeedbackMessage: React.FC<FeedbackMessageProps> = ({
  onSwitchToManual,
  email,
  setEmail,
  onSendEmail
}) => {
  return (
    <>
      <div className="feedback-message">
        <span>Not liking the results? Click "Get Insights" again or</span> 
        <button 
          className="inline-button"
          onClick={onSwitchToManual}
        >
          try <b>URL + Description 📝</b>
        </button>
        <span>for a fresh perspective! 🔄</span>
      </div>
      <div className="feedback-message">
        <span>Helping someone?</span>
        <button onClick={onSendEmail} className="inline-button">
          Send<b>💡Bullet Point Ideas</b>
        </button>
        <span>to</span>
        <span className="email-sender">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter an email"
            className="email-input"
          />
        </span>
        <span>to support their career 💪</span>
      </div>
    </>
  );
};