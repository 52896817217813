import React from 'react';
import './TermsOfService.css'; // Import the CSS file

export default function Preview() {
  return (
    <div className="app-container">
      <div className="main-content">
        <h1>Terms of Service</h1>

        <div className="last-updated">
          Last Updated: January 22, 2025
        </div>

        {/* Introduction Section */}
        <h2>1. Introduction</h2>
        <div className="section">
          <p>Welcome to Career Duck!</p>
          <p>
            These Terms of Service ("Terms") are a legal agreement between you and SocialAnimals, Inc., a Delaware company, doing business as Career Duck ("Career Duck", "careerduck.io", "we", "our", or "us") governing your use of careerduck.io and our AI-powered career and resume development services (collectively, the "Services"). By using our Services, you agree to these Terms.
          </p>
          <p>
            Our <a href="https://www.careerduck.io/privacy" className="link">Privacy Policy</a> explains how we collect and use personal information. Although it does not form part of these Terms, it is an important document that you should read.
          </p>
        </div>

        {/* Our Services Section */}
        <h2>2. Our Services</h2>
        <div className="section">
          <h3>2.1 Current Services</h3>
          <p>
            Career Duck is an AI-powered tool that helps you develop professional resumes by:
          </p>
          <ul>
            <li>Analyzing job postings you submit</li>
            <li>Providing job-specific summaries and insights</li>
            <li>Generating tailored resume content and bullet point ideas</li>
            <li>Saving outputs for review later (if you have an account)</li>
          </ul>
          <p>
            We strive to provide helpful and accurate content, but our AI-generated outputs must be reviewed and personalized by you before use in professional applications.
          </p>
          <h3>2.2 Future Features</h3>
          <p>
            We may introduce additional features, such as resume upload capabilities. When new features are introduced that involve the collection of additional personal information, we will update these Terms and our Privacy Policy accordingly. Continued use of the Services after such updates constitutes acceptance of the modified Terms.
          </p>
        </div>

        {/* Service Use and Accounts Section */}
        <h2>3. Service Use and Accounts</h2>
        <div className="section">
          <h3>3.1 Age Requirements</h3>
          <p>
            Our Services are designed for college students and professionals. By using our Services, you must be at least 13 years old and meet Google's account requirements.
          </p>

          <h3>3.2 Accounts</h3>
          <p>
            If you create an account using Google authentication, you agree to:
          </p>
          <ul>
            <li>Provide accurate information</li>
            <li>Maintain the security of your account credentials</li>
            <li>Be responsible for all activities under your account</li>
            <li>Notify us immediately of any unauthorized access</li>
            <li>Comply with any additional terms specific to account features</li>
          </ul>
          <p>
            Account access is provided through Google Authentication. By creating an account, you agree to provide accurate Google account information and maintain its accuracy.
          </p>
          <p>
          To delete your account and associated data, please email us at contact@careerduck.io with the subject line "Account Deletion Request." We will process your request within 30 days of receipt.
          </p>
        </div>

        {/* Content and AI Outputs Section */}
        <h2>4. Content and AI Outputs</h2>
        <div className="section">
          <h3>4.1 Your Inputs</h3>
          <p>
            When you submit job posting URLs or other content ("Inputs"), you:
          </p>
          <ul>
            <li>Represent that you have the right to share such content</li>
            <li>Grant us permission to analyze it for providing our Services</li>
            <li>Maintain ownership of your original content</li>
          </ul>

          <h3>4.2 AI-Generated Outputs</h3>
          <p>
            For content generated by our Services ("Outputs"):
          </p>
          <ul>
            <li>You may freely use our suggestions to help create and improve your resume</li>
            <li>You acknowledge that Outputs are AI-generated suggestions that require review and modification</li>
            <li>Similar suggestions may be provided to different users</li>
            <li>We don't guarantee accuracy or suitability of suggestions for any particular purpose</li>
            <li>You must exercise judgment when reviewing and incorporating our suggestions into your resume</li>
          </ul>
        </div>

        {/* Acceptable Use Section */}
        <h2>5. Acceptable Use</h2>
        <div className="section">
          <p>You agree not to:</p>
          <ul>
            <li>Submit false or misleading information</li>
            <li>Attempt to reverse engineer our Services</li>
            <li>Use our Services to harm others</li>
            <li>Attempt to scrape or bulk collect data</li>
            <li>Violate any applicable laws or regulations</li>
          </ul>
        </div>

        {/* Disclaimers and Limitations Section */}
        <h2>6. Disclaimers and Limitations</h2>
        <div className="section">
          <h3>6.1 Service Availability</h3>
          <p>
            We strive to maintain reliable Services but:
          </p>
          <ul>
            <li>May modify or discontinue features at any time</li>
            <li>Don't guarantee uninterrupted access</li>
            <li>May terminate accounts that violate these Terms</li>
          </ul>

          <h3>6.2 Disclaimers</h3>
          <p>
            THE SERVICES ARE PROVIDED "AS IS" WITHOUT WARRANTIES OF ANY KIND. We specifically disclaim:
          </p>
          <ul>
            <li>Accuracy of AI-generated content</li>
            <li>Fitness for any particular purpose</li>
            <li>Employment or career outcomes</li>
          </ul>

          <h3>6.3 Limitation of Liability</h3>
          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, CAREER DUCK SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING FROM YOUR USE OF THE SERVICES.
          </p>
        </div>

        {/* Changes to Terms Section */}
        <h2>7. Changes to Terms</h2>
        <div className="section">
          <p>
            We may update these Terms at any time by posting the revised version on our website. Your continued use of the Services after changes indicates your acceptance of the updated Terms.
          </p>
        </div>

        {/* Governing Law Section */}
        <h2>8. Governing Law</h2>
        <div className="section">
          <p>
            These Terms are governed by the laws of the State of Delaware, without regard to conflict of law principles.
          </p>
        </div>

        {/* Contact Section */}
        <h2>9. Contact Us</h2>
        <div className="section">
          <p>If you have questions about these Terms, please contact us at:</p>
          <p>Email: <span className="link">contact@careerduck.io</span></p>
        </div>

        <footer>
          This policy is effective as of January 22, 2025
        </footer>
      </div>
    </div>
  );
}