// services/jobService.ts
import axios from 'axios';
import { SubmissionData, JobData } from '../types/types';
import { recordSearch, getCurrentUser } from '../utils/firebase';
import { createUserHistoryRecord } from './userHistoryService';

interface ErrorResponse {
  error: string;
  message: string;
  code?: number;
}

const handleErrorResponse = (errorData: ErrorResponse): string => {
  // Groq API Service errors
  if (errorData.code === 503 || errorData.error === 'service_unavailable') {
    return 'Our AI service is temporarily unavailable. Please try again in a few minutes.';
  }
  if (errorData.code === 429 || errorData.error === 'rate_limit_exceeded') {
    return 'We\'re experiencing high demand. Please try again in a few minutes.';
  }
  if (errorData.code === 401 || errorData.error === 'authentication_error') {
    return 'There was an authentication error. Please try again.';
  }

  // Fetch errors
  if (errorData.error === 'fetch_error') {
    return 'Unable to fetch the job posting. Please try again later.';
  }

  // Job availability errors
  if (errorData.error === 'job_not_found') {
    return 'This job posting could not be found.';
  }
  if (['job_expired', 'job_removed', 'job_filled'].includes(errorData.error)) {
    return 'This position is no longer accepting applications.';
  }

  // Content validation errors
  if (errorData.error === 'content_too_long') {
    return 'This job posting is quite lengthy. Try using URL + Description to provide a condensed version.';
  }
  if (errorData.error === 'content_too_short') {
    return 'The job description seems too short. Please provide more details.';
  }
  if (errorData.error === 'missing_sections') {
    return 'The content does not appear to be a job description.';
  }
  if (errorData.error === 'missing_keywords') {
    return 'The content appears to be missing essential job information.';
  }
  if (errorData.error === 'invalid_content') {
    return 'Job description content is required.';
  }

  // Return the provided message or a default one
  return errorData.message || 'An unexpected error occurred. Please try again later.';
};

export const submitJobData = async (
  submissionData: SubmissionData,
  apiUrl: string,
  onSuccess: (data: JobData) => void,
  onError: (error: string) => void
) => {
  const startTime = performance.now();
  const currentUser = getCurrentUser();

  try {
    // Call Flask backend
    const response = await axios.post(`${apiUrl}/summarize`, submissionData);
    const jobData = response.data;
    
    // Record analytics in Search_Record
    const searchRecordId = await recordSearch({
      url: submissionData.url,
      job_title: jobData.parsed_summary.title,
      company: jobData.parsed_summary.company_name,
      response: {
        job_content: jobData.job_content,
        parsed_summary: jobData.parsed_summary,
        parsed_bullets: jobData.parsed_bullets
      },
      input_method: submissionData.method,
      processing_time_s: Math.round((performance.now() - startTime) / 1000)
    });

    // If user is authenticated, create user history record
    if (currentUser && typeof searchRecordId === 'string') {
      try {
        await createUserHistoryRecord(
          currentUser.uid,
          searchRecordId,
          {
            url: submissionData.url,
            job_title: jobData.parsed_summary.title,
            company: jobData.parsed_summary.company_name,
            response: {
              parsed_summary: jobData.parsed_summary,
              parsed_bullets: jobData.parsed_bullets
            }
          }
        );
      } catch (historyError) {
        // Log history creation error but don't fail the overall operation
        console.error('Error creating user history:', historyError);
      }
    }

    onSuccess(jobData);
    
  } catch (error: any) {
    // Record failed search in Search_Record
    await recordSearch({
      url: submissionData.url,
      job_title: null,
      company: null,
      response: null,
      error: error.message,
      input_method: submissionData.method,
      processing_time_s: Math.round((performance.now() - startTime) / 1000)
    });

    if (axios.isAxiosError(error)) {
      if (error.response) {
        // Server returned an error response (4xx, 5xx)
        const errorMessage = handleErrorResponse(error.response.data as ErrorResponse);
        onError(errorMessage);
      } else if (error.request) {
        // Network/Connection errors
        if (error.code === 'ERR_NETWORK' || error.message.includes('Network Error')) {
          onError('Please check your internet connection and try again.');
        }
        // Gateway Timeout
        else if (error.code === 'ECONNABORTED' || error.message.includes('timeout')) {
          onError('Connection timed out. Please check your internet connection and try again.');
        }
        // Any other request error
        else {
          onError('Unable to connect to the server. Please try again.');
        }
      } else {
        // Error setting up the request
        onError('An error occurred while processing your request. Please try again.');
      }
    } else {
      // Non-Axios error
      onError('An unexpected error occurred. Please try again later.');
    }
    
    // Re-throw the error for any additional error handling
    throw error;
  }
};