import React, { useState, useEffect, useRef } from 'react';
import { X } from 'lucide-react';
import { useAuth } from '../../hooks/useAuth';
import { useTheme } from '../../hooks/useTheme';
import './Settings.css';

interface SettingsProps {
  isOpen: boolean;
  onClose: () => void;
}

interface SettingsState {
  emailNotifications: boolean;
  displayName?: string;
}

const ThemeToggle: React.FC = () => {
  const { theme, toggleTheme } = useTheme();
  
  return (
    <label className="toggle-switch">
      <input 
        type="checkbox" 
        onChange={toggleTheme}
        checked={theme === 'dark'} 
      />
      <span className="toggle-slider">
        <span className="theme-icon moon">🌙</span>
        <span className="theme-icon sun">☀️</span>
      </span>
    </label>
  );
};

export const Settings: React.FC<SettingsProps> = ({ isOpen, onClose }) => {
  const { user } = useAuth();
  const modalRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState<'general' | 'profile'>('general');
  const [settings, setSettings] = useState<SettingsState>({
    emailNotifications: true
  });

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') onClose();
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
      return () => document.removeEventListener('keydown', handleEscape);
    }
  }, [isOpen, onClose]);

  const handleClickOutside = (e: React.MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
      onClose();
    }
  };

  if (!isOpen) return null;

  return (
    <div className="settings-overlay" onClick={handleClickOutside}>
      <div className="settings-content" ref={modalRef}>
        <div className="settings-header">
          <h2 className="settings-title">Settings</h2>
          <button onClick={onClose} className="settings-close-button">
            <X size={20} />
          </button>
        </div>

        <div className="settings-body">
          <div className="settings-sidebar">
            <button
              className={`settings-tab-button ${activeTab === 'general' ? 'active' : ''}`}
              onClick={() => setActiveTab('general')}
            >
              General
            </button>
            <button
              className={`settings-tab-button ${activeTab === 'profile' ? 'active' : ''}`}
              onClick={() => setActiveTab('profile')}
            >
              Profile
            </button>
          </div>

          <div className="settings-main">
            {activeTab === 'general' && (
              <div className="settings-section">
                <div className="settings-group">
                  <div className="settings-field">
                    <div className="settings-toggle">
                      <div className="toggle-description">
                        <span className="toggle-title">Dark Theme</span>
                      </div>
                      <ThemeToggle />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {activeTab === 'profile' && (
              <div className="settings-section">
                <div className="settings-group">
                  <div className="settings-field">
                    <label>Email</label>
                    <input 
                      type="email" 
                      value={user.email || ''} 
                      disabled 
                      className="settings-input"
                    />
                  </div>
                  <div className="settings-field">
                    <label>Display Name</label>
                    <input 
                      type="text" 
                      value={settings.displayName || user.displayName || ''} 
                      className="settings-input"
                      disabled 
                      onChange={(e) => setSettings(prev => ({
                        ...prev,
                        displayName: e.target.value
                      }))}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="settings-footer">
        </div>
      </div>
    </div>
  );
};