import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import './Footer.css'; // Import the CSS file

export const Footer: React.FC = () => {
    const { user } = useAuth();

    return (
        <div className="footer">
            {user
                ? <>
                    AI-generated bullet points must be reviewed and personalized before use in professional applications.
                  </>
                : (
                  <>
                    By using Career Duck, you agree to our{' '}
                    <Link 
                        to="/terms" 
                        style={{ 
                        color: 'var(--link)',
                        textDecoration: 'none'
                        }}
                    >
                        Terms
                    </Link>
                    {' '}and have read our{' '}
                    <Link 
                        to="/privacy" 
                        style={{ 
                        color: 'var(--link)',
                        textDecoration: 'none'
                        }}
                    >
                        Privacy Policy
                    </Link>
                    .
                  </>
                )
            }
        </div>
    );
};