import React from 'react';
import './PrivacyPolicy.css'; // Import the CSS file

export default function Preview() {
  return (
    <div className="app-container">
      <div className="main-content">
        <h1>Privacy Policy</h1>

        <div className="last-updated">
          Last Updated: January 22, 2025
        </div>

        {/* Introduction Section */}
        <h2>1. Introduction</h2>
        <div className="section">
          <p>
            SocialAnimals, Inc., doing business as Career Duck ("Career Duck", "careerduck.io", "we", "our", or "us") is committed to protecting your privacy. This Privacy Policy explains how we collect, use, and safeguard your information when you use our website (careerduck.io) and services (collectively, the "Services").
          </p>
        </div>

        {/* Information Collection Section */}
        <h2>2. Information We Collect</h2>
        <div className="section">
          <h3>2.1 Information You Provide</h3>
          <ul>
            <li><strong>Account Information</strong>: When you sign in using Google Auth, we collect your name and email address.</li>
            <li><strong>Service Usage Information</strong>: Job posting URLs you submit and the resume content you generate using our Services.</li>
            <li><strong>Communications</strong>: Information you provide when you contact us for support or feedback.</li>
            <li><strong>Resume Information</strong>: In the future, when you upload your resume, we may collect additional personal and demographic information contained within your resume. We will update this policy before implementing this feature to specify exactly what information we collect and how we use it.</li>
          </ul>

          <h3>2.2 Information Automatically Collected</h3>
          <ul>
            <li><strong>Usage Data</strong>: How you interact with our Services, including features used and time spent on the platform.</li>
            <li><strong>Device Information</strong>: Browser type, operating system, and device identifiers.</li>
            <li><strong>Cookies</strong>: We use cookies to maintain your session and improve your experience.</li>
          </ul>
        </div>

        {/* Information Usage Section */}
        <h2>3. How We Use Your Information</h2>
        <div className="section">
          <ul>
            <li>Provide and improve our Services</li>
            <li>Generate resume content based on job postings</li>
            <li>Analyze and enhance our AI systems' performance</li>
            <li>Communicate with you about our Services</li>
            <li>Ensure platform security and prevent abuse</li>
            <li>Maintain and improve our Services</li>
          </ul>
        </div>

        {/* Information Sharing Section */}
        <h2>4. Information Sharing</h2>
        <div className="section">
          <p>We may share your information with:</p>
          <ul>
            <li><strong>Service Providers</strong>: Companies that help us provide our Services (e.g., hosting providers, analytics services)</li>
            <li><strong>Educational Institutions</strong>: Aggregated, anonymized data and analytics about career development trends</li>
            <li><strong>Legal Requirements</strong>: When required by law or to protect our rights</li>
            <li><strong>Business Transfers</strong>: If we're involved in a merger, acquisition, or sale of assets</li>
          </ul>
          <p>We do not sell your personal information to third parties for marketing purposes. Any shared data is de-identified and presented in aggregate form.</p>
        </div>

        {/* Data Security Section */}
        <h2>5. Data Security</h2>
        <div className="section">
          <p>We implement reasonable security measures to protect your information. However, no method of transmission over the internet is 100% secure. We cannot guarantee absolute security.</p>
        </div>

        {/* Data Retention Section */}
        <h2>6. Data Retention</h2>
        <div className="section">
          <p>We retain your information for as long as necessary to provide our Services and comply with legal obligations. You can request deletion of your account at any time.</p>
        </div>

        {/* Your Rights Section */}
        <h2>7. Your Rights</h2>
        <div className="section">
          <ul>
            <li>Access your personal information</li>
            <li>Update or correct your information</li>
            <li>Delete your account and data</li>
            <li>Opt-out of marketing communications</li>
          </ul>
          <p>We will process your account deletion request within 30 days of receipt. Some information may be retained for legal compliance purposes.</p>
        </div>

        {/* Age Restrictions Section */}
        <h2>8. Age Requirements</h2>
        <div className="section">
          <p>Our Services are designed for college students and professionals. By using our Services, you confirm that you are at least 13 years old and meet Google's account requirements.</p>
        </div>

        {/* Policy Changes Section */}
        <h2>9. Changes to This Policy</h2>
        <div className="section">
          <p>We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the updated policy on our website.</p>
        </div>

        {/* Contact Section */}
        <h2>10. Contact Us</h2>
        <div className="section">
          <p>If you have questions about this Privacy Policy or our practices, please contact us at:</p>
          <p>Email: <span className="link">contact@careerduck.io</span></p>
        </div>

        <footer>
          This policy is effective as of January 22, 2025
        </footer>
      </div>
    </div>
  );
}