import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Menu, LogOut } from 'lucide-react';
import { useAuth } from '../../hooks/useAuth';
import { collection, query, orderBy, limit, where, onSnapshot, DocumentData } from 'firebase/firestore';
import { db } from '../../utils/firebase';
import { getUserHistoryRecord, UserHistoryRecord } from '../../services/userHistoryService';
import './Sidebar.css';

interface SidebarRecord {
  id: string;
  company: string;
  job_title: string;
  url: string;
  response: {
    parsed_summary: any;
    parsed_bullets: any;
  };
  favorite: boolean;
  status: 'active' | 'archived' | 'deleted';
}

interface SidebarProps {
  onExpandChange: (expanded: boolean) => void;
  onSearchRecordClick: (record: SidebarRecord) => void;
}

export const Sidebar: React.FC<SidebarProps> = ({ onExpandChange, onSearchRecordClick }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [historyRecords, setHistoryRecords] = useState<SidebarRecord[]>([]);
  const { signOut, user } = useAuth();

  useEffect(() => {
    if (!user) return;

    const q = query(
      collection(db, 'User_History'),
      where('userId', '==', user.uid),
      where('status', '==', 'active'),
      orderBy('timestamp', 'desc'),
      limit(10)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const records = snapshot.docs.map(doc => {
        const data = doc.data() as UserHistoryRecord;
        return {
          id: doc.id,
          company: data.company,
          job_title: data.job_title,
          url: data.url,
          response: data.response,
          favorite: data.favorite,
          status: data.status
        };
      });
      setHistoryRecords(records);
    }, (error) => {
      console.error('Error fetching history records:', error);
      setHistoryRecords([]);
    });

    return () => unsubscribe();
  }, [user]);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
    onExpandChange(!isExpanded);
  };

  const handleSignOut = async () => {
    await signOut();
  };

  const handleRecordClick = async (record: SidebarRecord) => {
    try {
      // If we already have the response data in the record, use it directly
      if (record.response.parsed_summary && record.response.parsed_bullets) {
        onSearchRecordClick(record);
        // Update lastViewed timestamp
        await getUserHistoryRecord(record.id);
        return;
      }
      
      // Fetch the full record and update lastViewed
      const fullRecord = await getUserHistoryRecord(record.id);
      
      if (fullRecord) {
        const sidebarRecord: SidebarRecord = {
          id: fullRecord.id,
          company: fullRecord.company,
          job_title: fullRecord.job_title,
          url: fullRecord.url,
          response: fullRecord.response,
          favorite: fullRecord.favorite,
          status: fullRecord.status
        };
        onSearchRecordClick(sidebarRecord);
      } else {
        throw new Error('Record not found');
      }
    } catch (error) {
      console.error('Error fetching record:', error);
      throw error;
    }
  };

  return (
    <div className={`sidebar ${isExpanded ? 'expanded' : 'collapsed'}`}>
      <div className="sidebar-header">
        <Menu className="menu-icon" />
        {isExpanded && <span className="navigation-title">Your History 🤩</span>}
        <button onClick={handleToggle} className="toggle-button">
          {isExpanded ? <ChevronLeft className="toggle-icon" /> : <ChevronRight className="toggle-icon" />}
        </button>
      </div>

      <div className="sidebar-content">
        {historyRecords.map((record) => (
          <div
            key={record.id}
            className="menu-item"
            onClick={() => handleRecordClick(record)}
          >
            {isExpanded && (
              <div className="record-container">
                <span className="record-title">{record.job_title}</span>
                <strong><span className="record-company">{record.company}</span></strong>
                {record.favorite && <span className="record-favorite">⭐</span>}
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="sidebar-footer">
        <div className="menu-item" onClick={handleSignOut}>
          <LogOut className="menu-icon" />
          {isExpanded && <span className="menu-text">Sign Out</span>}
        </div>
      </div>
    </div>
  );
};